<template>
  <mdb-container>
    <mdb-navbar class="flexible-navbar white" position="top" double scrolling>
      <mdb-navbar-brand @click.native="toggleSideNav" class="button-collapse">
        <mdb-icon icon="bars"/>
      </mdb-navbar-brand>
      <mdb-navbar-nav left>
        <mdb-breadcrumb>{{computedPage.activePage}}</mdb-breadcrumb>
      </mdb-navbar-nav>
      <mdb-navbar-toggler/>
      <mdb-navbar-nav class="right-nav" right>
        <mdb-nav-item @click.native="openSignOutModal()">
          <mdb-icon icon="sign-out-alt" class="light-green-text" /><span class="clearfix d-none d-sm-inline-block text-uppercase">Logout</span>
      </mdb-nav-item>
      </mdb-navbar-nav>
    </mdb-navbar>

    <!-- Modal: Sign Out -->
    <mdb-modal :show="showSignOutModal" @close="showSignOutModal = false">
      <mdb-modal-header color="red">
        <mdb-modal-title>Logout</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="sign-out-alt" size="4x" class="red-text ml-3"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie sich ausloggen?</strong>
            </p>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showSignOutModal = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" @click.native="signOut">
          Logout
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Sign Out -->
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavItem,
  mdbNavbarNav,
  mdbNavbarToggler,
  mdbBreadcrumb,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter
} from 'mdbvue'

import { mapActions } from 'vuex'

import axios from 'axios'
window.axios = axios
axios.defaults.baseURL = 'https://admin.frankpetzchen.de/api/'

export default {
  name: 'Navbar',
  props: {
    page: {
      type: String
    }
  },
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavItem,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbBreadcrumb,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter
  },
  data () {
    return {
      activePage: this.page,
      toggle: false,
      showSignOutModal: false,
      username: localStorage.getItem('username'),
      password: localStorage.getItem('password')
    }
  },
  methods: {
    ...mapActions(['toggleSideNav']),
    openSignOutModal() {
      this.showSignOutModal = true
    },
    signOut() {
      axios.get('logout.php').then(response => {
        if (response.data.override === true) {
          localStorage.setItem('username', 'newsweek')
          window.location.href = 'https://gww-lf.de'
        } else {
          localStorage.removeItem('loggedIn');
          localStorage.removeItem('username');
          localStorage.removeItem('password');
          window.location.href = 'https://admin.frankpetzchen.de'
        }
        this.showSignOutModal = false
      })
    }
  }, 
  computed: {
    computedPage () {
      return {
        activePage: this.page
      }
    }
  },
  mounted() {
    if (localStorage.username) {
      this.username = localStorage.username
    }
    if (localStorage.password) {
      this.password = localStorage.password
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.navbar .nav-item {
  line-height: 24px;
  font-size: 16px;
}
.navbar .dropdown-toggle {
  padding: 0 10px;
  font-size: 16px;
}

.navbar ul {
  padding: 5px 0;
}

.navbar .btn {
  font-weight: 300;
}

.navbar-collapse {
  order: 0 !important;
}

.navbar .notifications-nav .dropdown-menu {
  width: 23rem; }

.navbar-expand-lg .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.btn-transparent {
  background-color: transparent !important;
  color: #000;
  text-transform: none;
  box-shadow: none !important;
}

.btn-transparent:hover, .btn-transparent:active {
  box-shadow: none !important;
}

.breadcrumb {
  font-size: 20px !important;
  line-height: 22px !important;
  text-transform: uppercase;
}

</style>
